/**
 * It takes an object with three properties, and returns an object with two properties.
 * @returns An object with two properties: type and message.
 */
export const notificationCheck = ({ status, type, message }) => {

    let typeMessage

    typeof type === 'string' ? typeMessage = type.toLowerCase() : typeMessage = type

    let notificacionOptions = {}
    if (status === 'ERROR') {
        notificacionOptions = {
            type: 'danger',
            message
        }
    } else if (status === 'VALIDATION') {
        notificacionOptions = {
            type: 'warning',
            message
        }
    } else {
        notificacionOptions = {
            type: 'success',
            message
        }
    }
    return notificacionOptions
}