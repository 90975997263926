import { apiV2, userAxios } from "../index";

const cashGlobalList = async () => {
  try {
    return await userAxios.get(`v2/global-cash/daily`).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};
// const cashGlobalList = async () => {
//     try {
//         return await userAxios.get(`global_cash`).then(res => res.data)
//     } catch (error) {
//         console.log(error);
//     }
// }

const cashView = async (id) => {
  try {
    return await userAxios.get(`cash/${id}`).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalClose = async (data) => {
  try {
    return await userAxios
      .post(`global_cash/close`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalDaily = async () => {
  try {
    return await userAxios
      .get(`v2/global-cash/owner/resume/last-report`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalHistory = async (perPage, data) => {
  try {
    return await userAxios
      .post(`v2/global-cash/history/${perPage}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalHistoryPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`v2/global-cash/history/${perPage}?page=${page}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalHistoryFilter = async (id, perPage, data) => {
  try {
    return await userAxios
      .post(`v2/global-cash/facility/${id}/history/filter/${perPage}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalHistoryFilterPagination = async (id, perPage, page, data) => {
  try {
    return await userAxios
      .post(`dailyCashes/filter/${id}/${perPage}?page=${page}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const downloadReport = async (globalcashid) => {
  try {
    return await apiV2
      .post(
        `global-cash/download-report/${globalcashid}`,
        {},
        {
          responseType: "blob",
        }
      )
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalDailySwitch = async (data) => {
  try {
    return await userAxios
      .post(`v2/global-cash/cash/exchange-movement-type`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

export default {
  cashGlobalList,
  cashView,
  cashGlobalClose,
  cashGlobalDaily,
  cashGlobalHistory,
  cashGlobalHistoryFilter,
  cashGlobalHistoryPagination,
  cashGlobalHistoryFilterPagination,
  downloadReport,
  cashGlobalDailySwitch,
};
