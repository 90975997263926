var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('app-tour',{attrs:{"steps":_vm.steps}}),_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"8","xl":"10"}},[_c('h1',{staticClass:"p-1"},[_vm._v(_vm._s(_vm.$t("Hello"))+", "+_vm._s(_vm.user)+"!")])]),_c('b-col',{attrs:{"md":"12","lg":"4","xl":"2"}},[(_vm.CashStats)?_c('p',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold my-1"},[_vm._v("Opening: "+_vm._s(_vm.CashStats.date))]),(
            _vm.$can('close_cash', 'module_payment.daily_closing.run_closing')
          )?_c('b-button',{attrs:{"variant":"primary"}},[_c('router-link',{staticClass:"text-white",attrs:{"to":"cash-closing"}},[_vm._v(_vm._s(_vm.$t("CashAccount.Cash Closing")))])],1):_vm._e()],1):_vm._e()])],1),(_vm.CashStats)?_c('div',[_c('CardCash',{attrs:{"CashStats":_vm.CashStats}},[_c('b-col',[_c('div',{staticClass:"h-100 d-flex flex-wrap align-items-center justify-content-around card-global"},[_c('b-avatar',{staticClass:"shadow mb-2 text-center",attrs:{"variant":"light","size":"70"}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"size":"28","icon":"RepeatIcon"}})],1),_c('div',[_c('h3',{staticClass:"text-white"},[_vm._v("Transactions")]),_c('div',{staticClass:"d-flex align-items-start"},[_c('h3',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getTotalTransactions(_vm.CashStats))+" ")])])])],1)])],1)],1):_c('div',[_c('CardCash',{attrs:{"CashStats":{
        total: 0,
        cash: 0,
        cc: 0,
      }}},[_c('b-col',[_c('div',{staticClass:"h-100 d-flex flex-wrap align-items-center justify-content-around card-global"},[_c('b-avatar',{staticClass:"shadow mb-2 text-center",attrs:{"variant":"light","size":"70"}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"size":"28","icon":"RepeatIcon"}})],1),_c('div',[_c('h3',{staticClass:"text-white"},[_vm._v("Transactions")]),_c('div',{staticClass:"d-flex align-items-start"},[_c('h3',{staticClass:"text-white"},[_vm._v("0")])])])],1)])],1)],1),_c('b-table',{staticClass:"mt-2",attrs:{"small":"","bordered":"","fields":_vm.fields,"items":_vm.items,"responsive":"sm","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(show_details)",fn:function(row){return [_c('b-form-checkbox',{staticClass:"vs-checkbox-con",on:{"change":function($event){return _vm.onChangeRow(row)}},model:{value:(_vm.expandedRows[row.index]),callback:function ($$v) {_vm.$set(_vm.expandedRows, row.index, $$v)},expression:"expandedRows[row.index]"}})]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('CardCash',{attrs:{"CashStats":{
            total: row.item.total,
            cash: row.item.cash,
            cc: row.item.cc,
            cc_convenience_fee: row.item.cc_convenience_fee,
          }}},[_c('b-col',[_c('div',{staticClass:"h-100 d-flex flex-wrap align-items-center justify-content-around card-global"},[_c('b-avatar',{staticClass:"shadow mb-2 text-center",attrs:{"variant":"light","size":"70"}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"size":"28","icon":"RepeatIcon"}})],1),_c('div',[_c('h3',{staticClass:"text-white"},[_vm._v("Transactions")]),_c('div',{staticClass:"d-flex align-items-start"},[_c('h3',{staticClass:"text-white"},[_vm._v(" "+_vm._s(row.item.total_transactions)+" ")])])])],1)])],1),_c('b-table',{staticClass:"mt-2",attrs:{"small":"","bordered":"","responsive":"sm","show-empty":"","empty-text":"No matching records found","fields":_vm.fieldsCash,"items":row.item.movements},scopedSlots:_vm._u([{key:"cell(value)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":_vm.getColor(data.item.movementType)}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":_vm.getIcon(data.item.movementType)}}),_vm._v(" "+_vm._s("$ " + data.item.total)+" ")],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Swicth movement'),expression:"'Swicth movement'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon btn-sm",attrs:{"variant":"flat-primary","disabled":_vm.loading},on:{"click":function($event){return _vm.switchMovement(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"RepeatIcon","size":"16"}})],1)]}}],null,true)})],1)]}},{key:"cell(frontdesks)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.frontdesks.avatar}})]},proxy:true}],null,true)},[_c('b-link',{attrs:{"to":{
            name: 'web-settings-users',
            params: { id: data.item.frontdesks.id },
          }}},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"},[_c('span',[_vm._v(" "+_vm._s(data.item.frontdesks.name)+" ")])])])],1)]}},{key:"cell(income)",fn:function(data){return [_c('b-badge',{attrs:{"pill":"","variant":"success"}},[_vm._v(" "+_vm._s("$ " + data.item.total)+" ")])]}}])}),_c('ToastNotification',{ref:"toast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }