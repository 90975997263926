<template>
  <b-card id="tour-card" class="bg-primary text-white">
    <b-row>
      <slot />
      <b-col>
        <div
          class="h-100 d-flex align-items-center justify-content-start card-global"
        >
          <b-avatar variant="light" size="70" class="shadow mb-2 mr-2">
            <feather-icon size="28" icon="ActivityIcon" class="text-primary" />
          </b-avatar>
          <div class="">
            <h3 class="text-white text-custom">
              {{ $t("CashAccount.Global Balance") }}
            </h3>
            <div class="d-flex align-items-start">
              <h3 class="text-white">${{ CashStats.total }}</h3>
              <!-- <b-badge
                variant="light"
                class="text-dark demo-inline-spacing ml-1 badge-glow"
              >
                +200
              </b-badge> -->
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div
          class="h-100 d-flex align-items-center justify-content-start card-cash"
        >
          <b-avatar variant="light" size="70" class="shadow mb-2 mr-2">
            <feather-icon
              size="28"
              icon="DollarSignIcon"
              class="text-primary"
            />
          </b-avatar>
          <div class="">
            <h3 class="text-white text-custom">
              {{ $t("CashAccount.Cash") }}
            </h3>
            <div class="d-flex align-items-start">
              <h3 class="text-white">$ {{ CashStats.cash }}</h3>
              <!-- <b-badge
                variant="light"
                class="text-dark demo-inline-spacing ml-1 badge-glow"
              >
                +100
              </b-badge> -->
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div
          class="h-100 d-flex align-items-center justify-content-start card-informative"
        >
          <b-avatar variant="light" size="70" class="shadow mb-2 mr-2">
            <feather-icon
              size="28"
              icon="CreditCardIcon"
              class="text-primary"
            />
          </b-avatar>
          <div>
            <h3 class="text-white text-custom">
              {{ $t("Card") }}
            </h3>
            <div class="d-flex align-items-start">
              <h3 class="text-white">$ {{ CashStats.cc }}</h3>
              <!-- <b-badge
                variant="light"
                class="text-dark demo-inline-spacing ml-1 badge-glow"
              >
                +30
              </b-badge> -->
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div
          class="h-100 d-flex align-items-center justify-content-start card-informative"
        >
          <b-avatar variant="light" size="70" class="shadow mb-2 mr-2">
            <feather-icon
              size="28"
              icon="CreditCardIcon"
              class="text-primary"
            />
          </b-avatar>
          <div>
            <h3 class="text-white text-break text-custom">Card + Convenience Fee</h3>
            <div class="d-flex align-items-start">
              <h3 class="text-white">$ {{ CashStats.cc_convenience_fee }}</h3>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BRow, BCol, BAvatar, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BAvatar,
    BBadge,
  },
  props: {
    CashStats: {
      type: Object,
      required: true,
    },
    isHistory: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    colNumbers() {
      return this.isHistory ? 2 : 3;
    },
  },
  data() {
    return {};
  },
};
</script>

<style lanng="scss" scoped>
@media screen and (max-width: 1024px) {
  .text-custom {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 375px) {
  .text-custom {
    font-size: 1.1rem;
  }
}
</style>
