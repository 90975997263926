<template>
  <b-card>
    <app-tour :steps="steps" />
    <b-row>
      <b-col md="12" lg="8" xl="10">
        <h1 class="p-1">{{ $t("Hello") }}, {{ user }}!</h1>
      </b-col>
      <b-col md="12" lg="4" xl="2">
        <p v-if="CashStats" class="d-flex flex-column">
          <span class="font-weight-bold my-1"
            >Opening: {{ CashStats.date }}</span
          >
          <b-button
            variant="primary"
            v-if="
              $can('close_cash', 'module_payment.daily_closing.run_closing')
            "
          >
            <router-link to="cash-closing" class="text-white">{{
              $t("CashAccount.Cash Closing")
            }}</router-link>
          </b-button>
        </p>
      </b-col>
    </b-row>
    <div v-if="CashStats">
      <CardCash :CashStats="CashStats">
        <b-col>
          <div
            class="h-100 d-flex flex-wrap align-items-center justify-content-around card-global"
          >
            <b-avatar variant="light" size="70" class="shadow mb-2 text-center">
              <feather-icon size="28" icon="RepeatIcon" class="text-primary" />
            </b-avatar>
            <div>
              <h3 class="text-white">Transactions</h3>
              <div class="d-flex align-items-start">
                <h3 class="text-white">
                  {{ getTotalTransactions(CashStats) }}
                </h3>
              </div>
            </div>
          </div>
        </b-col>
      </CardCash>
    </div>
    <div v-else>
      <CardCash
        :CashStats="{
          total: 0,
          cash: 0,
          cc: 0,
        }"
      >
        <b-col>
          <div
            class="h-100 d-flex flex-wrap align-items-center justify-content-around card-global"
          >
            <b-avatar variant="light" size="70" class="shadow mb-2 text-center">
              <feather-icon size="28" icon="RepeatIcon" class="text-primary" />
            </b-avatar>
            <div>
              <h3 class="text-white">Transactions</h3>
              <div class="d-flex align-items-start">
                <h3 class="text-white">0</h3>
              </div>
            </div>
          </div>
        </b-col>
      </CardCash>
    </div>
    <b-table
      small
      bordered
      :fields="fields"
      :items="items"
      responsive="sm"
      class="mt-2"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="expandedRows[row.index]"
          class="vs-checkbox-con"
          @change="onChangeRow(row)"
        >
        </b-form-checkbox>
      </template>
      <template #row-details="row">
        <b-card>
          <CardCash
            :CashStats="{
              total: row.item.total,
              cash: row.item.cash,
              cc: row.item.cc,
              cc_convenience_fee: row.item.cc_convenience_fee,
            }"
          >
            <b-col>
              <div
                class="h-100 d-flex flex-wrap align-items-center justify-content-around card-global"
              >
                <b-avatar
                  variant="light"
                  size="70"
                  class="shadow mb-2 text-center"
                >
                  <feather-icon
                    size="28"
                    icon="RepeatIcon"
                    class="text-primary"
                  />
                </b-avatar>
                <div>
                  <h3 class="text-white">Transactions</h3>
                  <div class="d-flex align-items-start">
                    <h3 class="text-white">
                      {{ row.item.total_transactions }}
                    </h3>
                  </div>
                </div>
              </div>
            </b-col>
          </CardCash>
          <b-table
            small
            bordered
            responsive="sm"
            class="mt-2"
            show-empty
            empty-text="No matching records found"
            :fields="fieldsCash"
            :items="row.item.movements"
          >
            <template #cell(value)="data">
              <b-badge pill :variant="getColor(data.item.movementType)">
                <feather-icon
                  :icon="getIcon(data.item.movementType)"
                  class="mr-25"
                />
                {{ "$ " + data.item.total }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-button
                variant="flat-primary"
                class="btn-icon btn-sm"
                v-b-tooltip.hover.top="'Swicth movement'"
                @click="switchMovement(data.item.id)"
                :disabled="loading"
              >
                <feather-icon icon="RepeatIcon" size="16" />
              </b-button>
            </template>
          </b-table>
        </b-card>
      </template>

      <template #cell(frontdesks)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar size="32" :src="data.item.frontdesks.avatar" />
          </template>
          <b-link
            :to="{
              name: 'web-settings-users',
              params: { id: data.item.frontdesks.id },
            }"
          >
            <span
              class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start"
            >
              <span>
                {{ data.item.frontdesks.name }}
              </span>
            </span>
          </b-link>
        </b-media>
      </template>
      <!-- A virtual composite column -->
      <template #cell(income)="data">
        <b-badge pill variant="success">
          {{ "$ " + data.item.total }}
        </b-badge>
      </template>
    </b-table>
    <ToastNotification ref="toast" />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BBadge,
  BMedia,
  BAvatar,
  BButton,
  BLink,
  VBTooltip,
} from "bootstrap-vue";

import AppTour from "@core/components/app-tour/AppTour.vue";
import ToastNotification from "@/components/ToastNotification";

import axiosC from "@/services/cash-account";

import CardCash from "./CardCash";
import { notificationCheck } from "@/mixins/NotificationSetup";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BBadge,
    BMedia,
    BAvatar,
    BLink,
    BButton,
    VBTooltip,
    AppTour,
    ToastNotification,
    CardCash,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.getCash();
    setTimeout(() => {
      this.tourStart();
    }, 1500);
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("userData")).name,
      fields: [
        // A column that needs custom formatting
        { key: "show_details" },
        { key: "frontdesks", label: "Name" },
        // A virtual column made up from two fields
        { key: "income", label: "Amount", sortable: true },
        { key: "openingDate", sortable: true },
      ],
      fieldsCash: [
        { key: "patient_name", label: "Name" },
        { key: "detail", label: "Detail" },
        {
          key: "cash",
          label: "Cash",
        },
        {
          key: "cc",
          label: "Card",
        },
        {
          key: "convenience_fee",
          label: "Convenience Fee",
        },
        { key: "value", label: "Movement", sortable: true },
        { key: "transaction_id", label: "transaction ID" },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      CashStats: null,
      steps: [
        {
          target: "#tour-card",
          header: {
            title: "Cash section",
          },
          content: this.$t("Title Step Cash"),
        },
        {
          target: "#tour-card .card-global",
          header: {
            title: "Global balance",
          },
          content:
            "Here you can see all the balance sheet of the day, it will increase or subtract according to the cash movements.",
        },
        {
          target: "#tour-card .card-cash",
          header: {
            title: "Global cash balance",
          },
          content: "Total cash made on transactions ",
        },
        {
          target: "#tour-card .card-informative",
          header: {
            title: "Total money in debit/credit cards made in transactions ",
          },
          content: "You can click on this button to trigger the tour.",
        },
      ],
      visitStats: null,
      loading: false,
      expandedRows: {},
    };
  },
  methods: {
    getCash() {
      axiosC.cashGlobalList().then(({ registro }) => {
        this.visitStats = registro.visits;
        this.CashStats = registro.cash;
        this.items = registro.cash.cashes.map((item, index) => {
          return {
            ...item,
            _showDetails: this.expandedRows[index],
          };
        });
      });
    },
    getTotalTransactions(cashStats) {
      let total = 0;
      cashStats.cashes.forEach((box) => {
        total += box.total_transactions;
      });
      return total;
    },
    tourStart() {
      this.$tours.vuexyTour.start();
    },
    getColor(id) {
      if (id === "Income") return "success";
      if (id === "Egress") return "danger";
      if (id === "Not charge") return "secondary";
      return "success";
    },
    getIcon(id) {
      if (id === "Income") return "ArrowUpIcon";
      if (id === "Egress") return "ArrowDownIcon";
      if (id === "Not charge") return "MinusIcon";
      return "ArrowDownIcon";
    },
    nameComplete(appointment) {
      return `${appointment.patients.name} ${appointment.patients.lastname}`;
    },
    onChangeRow(row) {
      row.toggleDetails();
      this.expandedRows[row.index] = !row.detailsShowing;
    },
    switchMovement(idMovement) {
      this.loading = true;
      const data = {
        movement_id: idMovement,
      };
      axiosC.cashGlobalDailySwitch(data).then((res) => {
        const { type, message } = notificationCheck(res);
        this.$refs.toast[type](message);

        setTimeout(() => {
          this.getCash()
          this.loading = false;
        }, 2000);
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tour.scss";
</style>
